<template>
  <div class="l-wrapper">
    <div class="q-pa-md">
      <q-checkbox label="Show Today Only" v-model="is_today"></q-checkbox>
      <q-input outlined filled label="Search" v-model="searchTerm"></q-input>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-list class="">
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredVisit"
          :key="i"
          v-ripple
          @click="toDetail(val)"
        >
          <q-item-section>
            <q-item-label>{{ val.name }}</q-item-label>
            <q-item-label caption>{{
              moment(val.date).format("DD MMM YYYY")
            }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <div class="row q-gutter-sm justify-end">
              <q-btn
                v-show="curUser.is_sales == '1' && val.is_check_in == 0"
                outline
                color="secondary"
                label="Reschedule"
                @click.stop.prevent="
                  modalData = JSON.parse(JSON.stringify(val));
                  modalData.date = moment(modalData.date).format('YYYY/MM/DD');
                  schedule = true;
                "
              ></q-btn>
              <q-btn
                v-show="
                  curUser.is_sales == '1' &&
                    val.is_check_in == 0 &&
                    moment(val.date).format('DDMMYY') ==
                      moment().format('DDMMYY')
                "
                outline
                color="primary"
                label="Check-In"
                @click.stop.prevent="checkIn(val.id)"
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div class="row full-width" v-can="['SALES']">
      <q-btn
        class="no-border-radius col-6"
        color="secondary"
        label="ADD NO VISIT"
        @click="showNovisit = !showNovisit"
      ></q-btn>
      <q-btn
        class="no-border-radius col-6"
        color="primary"
        label="CREATE NEW VISIT"
        to="/visit/create"
      ></q-btn>
    </div>

    <q-dialog v-model="showNovisit">
      <q-card>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tanggal Visit :</label>
            <q-input
              outlined
              readonly
              v-model="novisit.date"
              mask="date"
              :rules="['date']"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="novisit.date" :options="optionsFn">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Status :</label>
            <q-select
              outlined
              v-model="novisit.tipe"
              :options="optStatus"
            ></q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Alasan :</label>
            <q-input
              outlined
              type="textarea"
              v-model="novisit.summary"
            ></q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="BATAL" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            :disable="!novisit.tipe && !novisit.tipe"
            @click="noVisit(novisit)"
            label="SIMPAN"
            color="primary"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="schedule">
      <q-card>
        <q-card-section>
          <q-date v-model="modalData.date" :options="optionsFn"></q-date>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="primary" label="Batal" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Simpan"
            @click="reschedule"
            v-close-popup
          >
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { computed, ref, inject } from "vue";
import { onMounted } from "vue";
import useVisit from "./useVisit";
import moment from "moment";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $http = inject("$http");
    const $q = useQuasar();
    const { getVisit, data, checkIn, curUser, noVisit } = useVisit();

    let is_today = ref(false);
    let searchTerm = ref("");
    let optStatus = ref(["SAKIT", "IZIN", "KANTOR"]);

    let novisit = ref({
      date: moment().format("YYYY/MM/DD"),
    });

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let modalData = ref({});
    let reschedule = async () => {
      let payload = {
        id: modalData.value.id,
        date: moment(modalData.value.date).format("YYYY-MM-DD"),
      };
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await $http.post(`/visit/reschedule`, payload);
        await getVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    };

    let filteredVisit = computed(() => {
      let visit = JSON.parse(JSON.stringify(data.visit));

      if (is_today.value) {
        let today = moment().format("YYYY-MM-DD");
        visit = visit.filter((val) => {
          return val.date == today;
        });
      }

      if (searchTerm.value != "") {
        visit = visit.filter((val) => {
          return (
            val.name.toLowerCase().indexOf(searchTerm.value.toLowerCase()) != -1
          );
        });
      }

      return visit;
    });

    let optionsFn = (date) => {
      return date >= moment().format("YYYY/MM/DD");
    };

    let toDetail = (val) => {
      if (curUser.value.is_sales == "1") {
        if (val.is_check_in == 1) {
          router.push(`/visit/sales-summary/${val.id}`);
        } else {
          $q.notify({
            message: "Silahkan Check-In Terlebih Dahulu",
            color: "negative",
          });
        }
      } else if (curUser.value.is_admin == "1") {
        router.push(`/visit/admin-summary/${val.id}`);
      }
    };

    return {
      schedule: ref(false),
      showNovisit: ref(false),
      getVisit,
      reschedule,
      modalData,
      data,
      checkIn,
      is_today,
      filteredVisit,
      searchTerm,
      curUser,
      novisit,
      optStatus,
      noVisit,
      moment,
      store,
      optionsFn,
      toDetail,
    };
  },
};
</script>
