import { reactive, inject, computed, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
export default function useUser() {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const $http = inject("$http");
  const $q = useQuasar();

  let curUser = computed(() => store.state.user);

  let data = reactive({
    visit: [],

    visitType: [],

    optYear: [],

    visitDetail: {
      is_check_in: "0",
    },

    img: null,
  });

  let visitHistoryList = ref([]);
  let pagination = reactive({
    search: "",
    filterType: [],
    filterDate: { from: null, to: null },
    currentPage: 1,
    totalPage: 1,
    rowPerPage: 10,
  });

  let getHistorySales = async (id) => {
    pagination.offset = pagination.rowPerPage * (pagination.currentPage - 1);
    pagination.limit = pagination.rowPerPage;
    let resp = await $http.post(`/visit/history/${id}`, pagination);
    visitHistoryList.value = resp.data.rows;

    if (visitHistoryList.value.length > 0) {
      pagination.totalPage = Math.ceil(
        resp.data.total_row / pagination.rowPerPage
      );
    }
  };

  let getHistoryAll = async () => {
    pagination.offset = pagination.rowPerPage * (pagination.currentPage - 1);
    pagination.limit = pagination.rowPerPage;
    let resp = await $http.post(`/visit/history`, pagination);
    visitHistoryList.value = resp.data.rows;

    if (visitHistoryList.value.length > 0) {
      pagination.totalPage = Math.ceil(
        resp.data.total_row / pagination.rowPerPage
      );
    }
  };

  let unlockVisit = async (bundle) => {
    let payload = {
      id: data.visitDetail.id,
    };
    // await $http.post(`/visit/unlock`, payload);

    let notifPayload = {
      id_from: curUser.value.id,
      id_to: data.visitDetail.id_user,
      router_to: `/visit/sales-summary/${data.visitDetail.id}`,
      content: `Kunci Summary Visit ${data.visitDetail.nama_customer} Telah Dibuka`,
    };
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/notification/add`, notifPayload);
      $q.notify({
        message: "Kunci Visit Summary Sales dibuka",
        color: "positive",
      });
      await getDetail(data.visitDetail.id);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let lockVisit = async (bundle) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      let payload = {
        id: data.visitDetail.id,
      };
      await $http.post(`/visit/lock`, payload);

      let notifPayload = {
        id_from: curUser.value.id,
        id_to: data.visitDetail.id_user,
        router_to: `/visit/sales-summary/${data.visitDetail.id}`,
        content: `Summary Visit ${data.visitDetail.nama_customer} Telah Dikunci`,
      };
      await $http.post(`/notification/add`, notifPayload);
      $q.notify({
        message: "Kunci Visit Summary Sales ditutup",
        color: "negative",
      });
      await getDetail(data.visitDetail.id);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let checkAdmin = async (bundle) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post("/visit/checkadmin", bundle);

      let notifPayload = {
        id_from: curUser.value.id,
        id_to: bundle.id_user,
        router_to: `/visit/sales-summary/${bundle.id}`,
      };
      if (bundle.is_valid == 1) {
        notifPayload.content = `Summary Visit ${bundle.nama_customer} telah di cek dan dinyatakan Valid`;
      } else {
        notifPayload.content = `Summary Visit ${bundle.nama_customer} telah di cek dan dinyatakan Invalid`;
      }
      await $http.post(`/notification/add`, notifPayload);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
    router.push("/visit/list");
  };

  let savepict = async (id) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      const d = new Date();
      let newname = `visit_${id}_${moment().format(
        "DDMMYY"
      )}_${d.getTime()}.png`;
      var formData = new FormData();
      formData.append("file", data.img);
      formData.append("nama", newname);

      await $http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      let bundle = {
        photo: newname,
        id: id,
      };

      await $http.post(`/visit/send-photo`, bundle);
      data.visitDetail.photo = newname;
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }

    router.push(`/visit/sales-summary/${id}`);
  };

  let getVisit = async () => {
    let resp = null;
    if (curUser.value.is_sales == "1") {
      resp = await $http.get(`/visit/list/${curUser.value.id}`);
    } else {
      resp = await $http.get(`/visit/list/`);
    }
    data.visit = resp.data;
  };

  let checkIn = async (id) => {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      let latlang = "";
      let latitude = parseFloat(pos.coords.latitude).toFixed(6);
      let longitude = parseFloat(pos.coords.longitude).toFixed(6);
      latlang = `${latitude},${longitude}`;
      let data = {
        id: id,
        check_in_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        coordinate: latlang,
      };
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await $http.post(`/visit/checkin`, data);
        await getVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });
  };

  let getType = async () => {
    let resp = await $http.get(`/visit-type/list-type`);
    let temp = [];
    for (let item of resp.data) {
      temp.push(item.name);
    }
    data.visitType = temp;
  };

  let addVisit = async () => {
    data.visitDetail.id_customer = data.visitDetail.id_customer.id;
    data.visitDetail.id_user = curUser.value.id;
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      let resp = await $http.post(`/visit/create`, data.visitDetail);
      if (data.visitDetail.is_check_in == "1") {
        await checkIn(resp.data);
      }
      data.visitDetail = {
        is_check_in: "0",
      };
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
    router.push("/visit");
  };

  let writeSummary = async (data) => {
    await $http.post(`/visit/write-summary`, data);

    let notifPayload = {
      id_from: curUser.value.id,
      term: ["is_admin"],
      content: `Summary visit ${data.nama_customer} telah dibuat. Mohon Diperiksa!`,
      router_to: `/visit/admin-summary/${data.id}`,
    };
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/notification/broadcastrole`, notifPayload);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
    router.push("/visit");
  };

  let sendPhoto = async (data) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/visit/send-photo`, data);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let checkVisit = async (data) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/visit/check`, data);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let getDetail = async (id) => {
    let resp = await $http.get(`/visit/detail/${id}`);
    data.visitDetail = resp.data;
  };

  let noVisit = async (data) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      data.id_user = curUser.value.id;
      data.tipe = `NO-VISIT-${data.tipe}`;
      await $http.post(`/visit/no-visit`, data);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let getOptYear = async () => {
    let temp = [];
    let resp = await $http.get(`/visit/minmaxyear`);
    for (let item of resp.data) {
      temp.push(item.year);
    }
    temp.unshift("Semua Tahun");
    data.optYear = temp;
  };

  let saveSummaryByAdmin = async (data) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/visit/savesummaryadmin`, data);
      $q.notify({ message: "Perubahan Berhasil Disimpan", color: "positive" });
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  return {
    getOptYear,
    getHistorySales,
    getHistoryAll,
    store,
    data,
    router,
    route,
    curUser,
    getVisit,
    checkIn,
    getType,
    addVisit,
    writeSummary,
    sendPhoto,
    checkVisit,
    getDetail,
    noVisit,
    savepict,
    unlockVisit,
    checkAdmin,
    lockVisit,
    saveSummaryByAdmin,
    visitHistoryList,
    pagination,
    $q,
  };
}
